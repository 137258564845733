import React, { FC } from 'react';
import { graphql } from 'gatsby';

import './ContactPage.scss';

import Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ContactPageProps } from './models';

const ContactPage: FC<ContactPageProps> = ({
  data: {
    contact: {
      name,
      phoneNumber,
      phoneNumberLabel,
      title,
      availability,
      availabilityLabel,
      email,
      emailLabel,
      header,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
    },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
  location: { pathname },
}) => {
  const crumbsSettings = {
    crumbs,
    overWrittenPath: pathname,
    overWrittenLabel: name,
  };

  return (
    <Layout
      crumbsSettings={crumbsSettings}
      seo={{ seoMetaKeywords, seoMetaTitle, seoMetaDescription }}
    >
      <Container className="contact-page" fluid>
        <Row>
          <Col>
            <PageTitle pageTitle={title} />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs="12" md="7" lg="7" xl="5">
            <div className="contact-page__content">
              <legend className="contact-page__title">{header}</legend>
              <div className="contact-page__wrapper">
                <span className="contact-page__label">{phoneNumberLabel}</span>
                <a className="contact-page__link" aria-label={phoneNumber} href={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </a>
              </div>
              <div className="contact-page__wrapper">
                <span className="contact-page__label">{emailLabel}</span>
                <a className="contact-page__link" aria-label={email} href={`mailto:${email}`}>
                  {email}
                </a>
              </div>
              <div className="contact-page__wrapper">
                <span className="contact-page__label">{availabilityLabel}</span>
                <p>{availability}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  {
    contact {
      id
      name
      phoneNumber
      phoneNumberLabel
      title
      availability
      availabilityLabel
      email
      emailLabel
      header
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
    }
  }
`;

export default ContactPage;
